import { useState } from 'react';
import InputAmount from '../../inputs/InputAmount';
import InputPrice from '../../inputs/InputPrice';

const RowProduct = (props) => {
  const { count: amount = 0, name = '', price = 0 } = props;
  //const { name = '' } = props;

  // const [amount, setAmount] = useState(Number(props.count) || 0);
  // const [price, setPrice] = useState(Number(props.price) || 0);

  // EventHandler ========
  const handleChangeAmount = (v) => {
    // setAmount(v);
    props.onChangeAmount(v);
  };
  const handleChangePrice = (v) => {
    // setPrice(v);
    props.onChangePrice(v);
  };

  const handleClickDelete = (e) => {
    props.onClickDelete();
  };

  return (
    <tr className="row-product">
      <td>
        <button className="btn btn-danger button-delete-product" onClick={handleClickDelete}>
          <i className="fas fa-trash-can"></i>
          <span className="sr-only">Delete</span>
        </button>
      </td>
      <td>
        <InputAmount
          amount={amount}
          onChangeAmount={(v) => {
            handleChangeAmount(v);
          }}
        />
      </td>
      <td>{name}</td>
      <td>
        <InputPrice price={price} onChangePrice={handleChangePrice} />
      </td>
      <td>{Number(amount * price).toFixed(2)}</td>
    </tr>
  );
};
export default RowProduct;
