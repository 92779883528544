import { useState } from 'react';
import RowProduct from './content/RowProduct';

import './TableInventory.scss';

const TableInventory = (props) => {
  // const { items = [] } = props;

  const [items, setItems] = useState(props.items || []);

  // const items = [
  //   {
  //     count: 1,
  //     name: 'Product',
  //     price: 10.2,
  //   },
  // ];

  // Event Handler ==========
  const handleChangeAmountByIndex = (amount, idx) => {
    const copyItems = [...items]; // items.slice()
    copyItems[idx].count = amount;
    setItems(copyItems);
  };

  const handleChangePriceByIndex = (price, idx) => {
    const copyItems = [...items]; // items.slice()
    copyItems[idx].price = price;
    setItems(copyItems);
  };

  const handleClickDeleteByIndex = (idx) => {
    const copyItems = [...items]; // items.slice()
    copyItems.splice(idx, 1);
    setItems(copyItems);
  };

  const displayTotalPrice = () => {
    const totalPrice = items.reduce((sum, item) => {
      return sum + Number(item.count) * Number(item.price);
    }, 0);
    return totalPrice.toFixed(2);
  };

  return (
    <div className="m-table-inventory">
      <div className="container">
        <h2>Table Inventory</h2>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Count</th>
              <th>Name</th>
              <th>Price</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {
              items.length > 0 &&
                items.map((product, idx) => {
                  return (
                    <RowProduct
                      key={`product-${idx}`}
                      {...product}
                      onChangeAmount={(v) => handleChangeAmountByIndex(v, idx)}
                      onChangePrice={(v) => handleChangePriceByIndex(v, idx)}
                      onClickDelete={() => handleClickDeleteByIndex(idx)}
                    />
                  );
                }) //
            }
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={4}>Total:</td>
              <td className="td-total">{displayTotalPrice()}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};
export default TableInventory;
