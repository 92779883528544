import React, { useState } from 'react';

import './InputAmount.scss';

const InputAmount = (props) => {
  // const amount = 0;
  const MIN_AMOUNT = Number(props.min) || 0;
  const MAX_AMOUNT = Number(props.max) || 9;

  // const [amount, setAmount] = useState(Number(props.amount) || 0);
  const amount = Number(props.amount) || 0;

  // EventHandler ==========
  const handleClickInc = (e) => {
    // setAmount(Number(amount) + 1);
    props.onChangeAmount(Number(amount) + 1);
  };

  const handleClickDec = (e) => {
    // setAmount(Number(amount) - 1);
    props.onChangeAmount(Number(amount) - 1);
  };

  return (
    <div className="m-input-amount input-amount">
      <div className="input-group mb-3">
        <span className="input-group-text">
          <button className="btn btn-dark button-dec" onClick={handleClickDec} disabled={amount <= MIN_AMOUNT}>
            <i className="fas fa-minus"></i>
            <span className="sr-only">Decrease</span>
          </button>
        </span>
        <input type="number" className="form-control" readOnly aria-label="Amount" value={amount} />
        <span className="input-group-text">
          <button className="btn btn-dark button-inc" onClick={handleClickInc} disabled={amount >= MAX_AMOUNT}>
            <i className="fas fa-plus"></i>
            <span className="sr-only">Increase</span>
          </button>
        </span>
      </div>
    </div>
  );
};

export default InputAmount;
