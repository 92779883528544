import TableInventory from './components/tables/TableInventory';

import inventoryItems from './data/inventory-items';

const App = (props) => {
  // const { } = props;

  return (
    <>
      <header></header>
      <main className="py-5">
        <TableInventory items={inventoryItems} />
      </main>
      <footer></footer>
    </>
  );
};
export default App;
