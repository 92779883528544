import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

const root = createRoot(document.querySelector('#app'));

root.render(<App />);

// vor React 18
// ReactDOM.render(<App /> , document.querySelector('#app'))
