const inventory = [
  {
    count: 2,
    name: 'Klingon Letter Opener',
    price: 15.0,
  },
  {
    count: 1,
    name: '3D Printing Pen',
    price: 20.0,
  },
  {
    count: 3,
    name: '8-Bit Heat-Change Mug',
    price: 6.0,
  },
];

export default inventory;
