// import { useState } from 'react';

import './InputPrice.scss';

const InputPrice = (props) => {
  // const { } = props;

  //const price = 0;

  // const [a, b] = [0, () => { console.log('funktion')}]
  // const [price, setPrice] = useState(Number(props.price) || 0);
  const price = Number(props.price) || 0;

  // EVENTHANDLER ======
  const handleChangePrice = (e) => {
    const value = Number(e.target.value);
    console.log(value);

    // Guard
    // if (value < 0) return;

    // setPrice(value); // Durch verändern der Variable über die setter Funktion von der useState Hook, wird die Komponente (JSX Struktur) neu gerendert (wenn es die Variable zur Ausgabe besitzt)

    props.onChangePrice(value); // value an Attribut onChangePrice übergeben
  };

  return (
    <div className="m-input-price">
      <div className="input-group mb-3">
        <span className="input-group-text">$</span>
        <input
          type="number"
          step="0.01"
          min="0"
          className="form-control"
          aria-label="Price"
          style={{ maxWidth: '120px' }}
          value={price}
          onChange={handleChangePrice}
          // style="max-width: 120px; border-radius: 16px"
        />
      </div>
    </div>
  );
};
export default InputPrice;
